import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  HStack,
  VStack,
  Button,
  Tooltip,
  Switch,
  Text,
  NumberInput,
  NumberInputField,
  Image,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";

const Betting = ({
  isLoading,
  betAmount,
  handleBetAmountChange,
  startNewGame,
  maxBet,
  betMaxEnabled,
  setBetMaxEnabled,
  fetchGameData,
}) => {
  const [hasInteracted, setHasInteracted] = useState(false);
  const lastFetchedMaxBetRef = useRef(null);

  const numericBet = parseFloat(betAmount);
  const numericMaxBet = parseFloat(maxBet || "0");
  const skipUpperBound = numericMaxBet <= 0;

  const isBetAmountValid =
    !isNaN(numericBet) &&
    numericBet >= 0.001 &&
    (skipUpperBound || numericBet <= numericMaxBet);

  // Only show tooltip if user has typed something invalid
  const tooltipMessage =
    !isBetAmountValid && betAmount !== ""
      ? skipUpperBound
        ? "Bet must be at least 0.001"
        : `Bet must be between 0.001 and ${numericMaxBet}`
      : "";

  // Safely update the bet amount (only numeric or blank)
  const setBetAmountProgrammatically = useCallback(
    (value) => {
      if (value === "" || /^(\d+(\.\d*)?|\.\d*)$/.test(value)) {
        handleBetAmountChange(value);
      }
    },
    [handleBetAmountChange]
  );

  // If user types in the bet, disable Bet Max
  const handleUserBetChange = useCallback(
    (valueString) => {
      setHasInteracted(true);
      if (betMaxEnabled) {
        setBetMaxEnabled(false);
      }
      setBetAmountProgrammatically(valueString);
    },
    [betMaxEnabled, setBetMaxEnabled, setBetAmountProgrammatically]
  );

  // Click handler for "Bet Max" switch/box
  const handleBetMaxClick = useCallback(() => {
    if (isLoading) return;
    setHasInteracted(true);
    setBetMaxEnabled((prev) => !prev);
  }, [isLoading, setBetMaxEnabled]);

  // Effect to re-fetch maxBet and set betAmount if BetMax is enabled
  useEffect(() => {
    if (!betMaxEnabled || isLoading) return;
    const parentMax = parseFloat(maxBet) || 0;
    if (parentMax <= 0) return;

    if (lastFetchedMaxBetRef.current === parentMax) return;
    if (Math.abs(parseFloat(betAmount) - parentMax) < 1e-9) {
      lastFetchedMaxBetRef.current = parentMax;
      return;
    }

    const recalcIfEnabled = async () => {
      try {
        const updatedData = await fetchGameData();
        if (updatedData) {
          const freshMaxBet = updatedData.maxBet?.toString() || "0";
          const parsedMax = parseFloat(freshMaxBet);

          if (parsedMax >= 0.001) {
            setBetAmountProgrammatically(freshMaxBet);
            lastFetchedMaxBetRef.current = parsedMax;
          } else {
            // If new max is too small, turn off BetMax
            setBetMaxEnabled(false);
            setHasInteracted(false);
          }
        }
      } catch (err) {
        console.error("BetMax recalc failed:", err);
        setBetMaxEnabled(false);
        setHasInteracted(false);
      }
    };

    recalcIfEnabled();
  }, [
    betMaxEnabled,
    maxBet,
    isLoading,
    betAmount,
    fetchGameData,
    setBetAmountProgrammatically,
    setBetMaxEnabled,
    setHasInteracted,
  ]);

  return (
    <VStack
      spacing={2}
      p={4}
      bg="#2f5a46"
      borderRadius="10px"
      border="1px solid whitesmoke"
      boxShadow="0px 4px 8px rgba(0, 0, 0, 0.25)"
      align="start"
    >
      <HStack align="center" spacing={3}>
        <Tooltip
          label={tooltipMessage}
          isOpen={hasInteracted && !isBetAmountValid && betAmount !== ""}
          hasArrow
          bg="red.600"
          color="whitesmoke"
          placement="top"
        >
          <InputGroup w="110px">
            {/* Polygon logo */}
            <InputLeftElement pointerEvents="none">
              <Image src="/polygon.svg" alt="Polygon logo" boxSize="35px" />
            </InputLeftElement>

            {/* Number Input for the bet amount (no steppers) */}
            <NumberInput
              min={0.001}
              max={skipUpperBound ? undefined : numericMaxBet}
              clampValueOnBlur={false}
              value={betAmount}
              onChange={(valueStr) => handleUserBetChange(valueStr)}
              isInvalid={hasInteracted && !isBetAmountValid && betAmount !== ""}
              isDisabled={isLoading}
              w="110px"
            >
              <NumberInputField
                name="betAmount"
                bg="#35654d"
                color="whitesmoke"
                _hover={{ borderColor: "whitesmoke" }}
                _focus={{ borderColor: "whitesmoke" }}
                borderRadius="md"
                textAlign="right"
                pr={2}
                w="110px"
              />
            </NumberInput>
          </InputGroup>
        </Tooltip>

        <Button
          onClick={startNewGame}
          size="md"
          bg="#35654d"
          color="whitesmoke"
          border="1px solid whitesmoke"
          _hover={{ backgroundColor: "#2f5a46", transform: "scale(1.02)" }}
          _active={{ backgroundColor: "#254c3a" }}
          isDisabled={!isBetAmountValid || isLoading}
          w="60px"
        >
          Bet
        </Button>

        <HStack
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          bg="#35654d"
          border="1px solid whitesmoke"
          borderRadius="10px"
          boxShadow="0px 4px 8px rgba(0, 0, 0, 0.25)"
          cursor="pointer"
          _hover={{ backgroundColor: "#2f5a46", transform: "scale(1.02)" }}
          onClick={handleBetMaxClick}
          h="40px"
          pl={2}
          pr={2}
        >
          <Switch
            isChecked={betMaxEnabled}
            onChange={() => {}}
            colorScheme="green"
            size="sm"
            isDisabled={isLoading}
            pointerEvents="none"
            name="betMax"
          />
          <Text fontSize="md" color="whitesmoke" wordBreak="keep-all">
            Bet&nbsp;Max
          </Text>
        </HStack>
      </HStack>
    </VStack>
  );
};

export default React.memo(Betting);
