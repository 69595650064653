// utils/ethersUtils.js
import { ethers } from "ethers";
import BlackjackABI from "../BlackjackABI.json";

export const getContract = (signer, blackjackAddress) => {
  if (!signer) {
    throw new Error(
      "Signer is not defined. Ensure you are passing a valid signer."
    );
  }
  return new ethers.Contract(blackjackAddress, BlackjackABI, signer);
};

export const executeContractFunction = async ({
  signer,
  blackjackAddress,
  setIsLoading,
  fetchGameData,
  addAlert,
  updateBalances = () => {},
  functionName,
  functionArgs = [],
  value = null,
  setLoadingAction,
  maxRetries = 20,
  lastRetry = false, // If true and fails, we'll show an alert
}) => {
  setIsLoading(true);
  let attempts = 0;
  let success = false;
  let lastError = null; // Store the last caught error here

  if (!signer) {
    addAlert({
      status: "error",
      title: "Signer Not Available",
      description: "Signer is not available for transaction execution.",
    });
    setIsLoading(false);
    return;
  }

  const contract = getContract(signer, blackjackAddress);

  let shouldBreakEarly = false;

  while (attempts < maxRetries && !success && !shouldBreakEarly) {
    attempts++;

    try {
      const tx = value
        ? await contract[functionName](...functionArgs, { value })
        : await contract[functionName](...functionArgs);

      await tx.wait();

      await fetchGameData();
      updateBalances();
      success = true;
    } catch (err) {
      lastError = err; // Save for potential final alert
      const reasonMatch = err?.message?.match(/reason="([^"]+)"/);
      const reason = reasonMatch ? reasonMatch[1] : "";

      // Decide if we break early
      if (err.code === "INSUFFICIENT_FUNDS") {
        shouldBreakEarly = true;
      } else if (err.code === "CALL_EXCEPTION") {
        // If it's specifically "Bet must be <= maxBet", keep going
        if (!reason.includes("Bet must be <= maxBet")) {
          // Some other revert reason is fatal
          shouldBreakEarly = true;
        }
      }

      // If not breaking, wait before next attempt
      if (!shouldBreakEarly && attempts < maxRetries) {
        const delay = 4000; // 4 second
        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }
  }

  // If we're still not successful after all retries, throw the last error
  if (!success) {
    // Only show an alert if lastRetry is true, or handle however you like
    if (lastRetry && lastError) {
      const reasonMatch = lastError.message.match(/reason="([^"]+)"/);
      const reason = reasonMatch ? reasonMatch[1] : "An unknown error occurred";

      addAlert({
        status: "error",
        title: `Error during ${functionName}`,
        description: `Reason: ${reason}`,
      });
    }

    // Important: re-throw so the caller sees it failed
    throw lastError;
  }

  setLoadingAction("");
  setIsLoading(false);
};
