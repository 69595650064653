// Shuffling.js
import React, { useMemo } from "react";
import { Box } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import Card from "./Card";

function looseInterleave(leftPile, rightPile) {
  const combined = [];
  let l = 0;
  let r = 0;

  while (l < leftPile.length || r < rightPile.length) {
    // Randomly take 1–3 cards from the left
    const leftTake = Math.floor(Math.random() * 3) + 1;
    for (let i = 0; i < leftTake && l < leftPile.length; i++) {
      combined.push(leftPile[l]);
      l++;
    }

    // Randomly take 1–3 cards from the right
    const rightTake = Math.floor(Math.random() * 3) + 1;
    for (let i = 0; i < rightTake && r < rightPile.length; i++) {
      combined.push(rightPile[r]);
      r++;
    }
  }

  return combined;
}

const Shuffling = ({ colorScheme }) => {
  const deckSize = 52;

  // UseMemo so this only runs once per mount
  const { combinedDeck, randomParams, zIndices } = useMemo(() => {
    // 1) Random cut of the deck
    const cutPoint = Math.floor(Math.random() * deckSize);
    const leftDeck = Array.from({ length: cutPoint }, (_, i) => ({
      side: "left",
      index: i,
    }));
    const rightDeck = Array.from({ length: deckSize - cutPoint }, (_, i) => ({
      side: "right",
      index: i,
    }));

    // 2) Loosely interleave
    const combined = looseInterleave(leftDeck, rightDeck);

    // 3) Random angles/offsets (increased for a wider spread).
    const params = combined.map(() => ({
      startX: (Math.random() - 0.5) * 1000, // -500 to 500 horizontally
      startY: (Math.random() - 0.5) * 600, // -300 to 300 vertically
      startRot: (Math.random() - 0.5) * 180, // -20 to 20 deg rotation
    }));

    // 4) Randomize z-indices
    const zArr = Array.from({ length: combined.length }, (_, i) => i + 1);
    for (let i = zArr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [zArr[i], zArr[j]] = [zArr[j], zArr[i]];
    }

    return {
      combinedDeck: combined,
      randomParams: params,
      zIndices: zArr,
    };
  }, [deckSize]);

  // Keyframe for fading all cards in at once
  const fadeIn = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `;

  // Keyframe for moving a card from its random position into the deck
  const moveIn = (startX, startY, startRot) => keyframes`
    0% {
      opacity: 1;
      transform: translate(${startX}px, ${startY}px) rotate(${startRot}deg);
    }
    100% {
      opacity: 1;
      transform: translate(-50%, -50%) rotate(0deg);
    }
  `;

  // Animation timings
  const fadeInDuration = 1; // All fade in over 1s
  const moveInDuration = 1.0; // Move into deck over 1s
  const cardDelayIncrement = 0.1; // Each subsequent card starts movement 0.1s later

  return (
    <Box position="absolute" height="100%" width="100%" overflow="hidden">
      {combinedDeck.map((cardData, idx) => {
        const { startX, startY, startRot } = randomParams[idx];

        // Each card will:
        // 1) Fade in (all at once),
        // 2) Then move in, staggered by index.
        const moveDelay = fadeInDuration + idx * cardDelayIncrement;

        return (
          <Box
            key={`${cardData.side}-${cardData.index}`}
            position="absolute"
            top="50%"
            left="50%"
            style={{
              transform: `translate(${startX}px, ${startY}px) rotate(${startRot}deg)`,
            }}
            animation={`
              ${fadeIn} ${fadeInDuration}s ease-out 0s forwards,
              ${moveIn(
                startX,
                startY,
                startRot
              )} ${moveInDuration}s ease-out ${moveDelay}s forwards
            `}
            zIndex={zIndices[idx]}
            pointerEvents="none"
          >
            <Card
              rank="?"
              suit="?"
              colorScheme={colorScheme}
              shuffling={true}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default Shuffling;
