import React, { useState, useCallback, useRef, useEffect } from "react";
import { VStack, Box, IconButton } from "@chakra-ui/react";
import { ethers, Wallet } from "ethers";
import { useAccount, useChainId, useWalletClient } from "wagmi";
import { FaCompress, FaExpand } from "react-icons/fa";
import Confetti from "react-confetti";
import { testAccounts } from "./testAccounts";
import useColorScheme from "./hooks/useColorScheme";
import useFetchGameData from "./hooks/useFetchGameData";
import useCardAnimation from "./hooks/useCardAnimation";
import { executeContractFunction } from "./utils/ethersUtils";
import DealerHand from "./components/DealerHand";
import PlayerHands from "./components/PlayerHands";
import Betting from "./components/Betting";
import PlayerAccount from "./components/PlayerAccount";
import WelcomeScreen from "./components/WelcomeScreen";
import Shuffling from "./components/Shuffling";

const Blackjack = ({
  addAlert,
  fullscreen,
  toggleFullscreen,
  isStandalone,
}) => {
  const { address: connectedAddress, isConnected } = useAccount();
  useWalletClient();
  const chain = useChainId();
  const [blackjackAddress, setBlackjackAddress] = useState(null);

  // Queue to process card animations so we don't stack them
  const updateQueue = useRef(Promise.resolve());

  // Player address management
  const [playerAddress, setPlayerAddress] = useState(() => {
    const stored = localStorage.getItem("playerAddress");
    return stored || null;
  });

  // Provider and signer
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);

  // Various UI toggles
  const [showLabel, setShowLabel] = useState(false);
  const [showGameActions, setShowGameActions] = useState(false);
  const [showBetButton, setShowBetButton] = useState(false);
  const [confettiRunning, setConfettiRunning] = useState(false);
  const [confettiRecycle, setConfettiRecycle] = useState(false);
  const [loadingAction, setLoadingAction] = useState("");
  const [showGameStatus, setShowGameStatus] = useState(false);
  const [showPayoutMessage, setShowPayoutMessage] = useState(false);
  const [betMaxEnabled, setBetMaxEnabled] = useState(false);
  const [showShuffling, setShowShuffling] = useState(false);

  // Bet amount logic - initialize from localStorage exactly once
  const [betAmount, setBetAmount] = useState(
    () => localStorage.getItem("betAmount") || "1"
  );
  // Whenever bet changes, save to localStorage
  useEffect(() => {
    localStorage.setItem("betAmount", betAmount);
  }, [betAmount]);

  // Fetching data about the game
  const { gameData, setGameData, fetchGameData, isLoading, setIsLoading } =
    useFetchGameData(playerAddress, provider, blackjackAddress);

  const { totalEscrow, dealerBankroll, playerBankroll } = gameData || {};

  const [colorScheme, setNewColorScheme] = useColorScheme();

  // For flipping animations
  const initializeCardShown = useCallback(
    () => ({
      player: (gameData.playerHands || []).map((hand) =>
        (hand.cards || []).map(() => false)
      ),
      dealer: (gameData.dealerHand || []).map(() => false),
    }),
    [gameData?.playerHands, gameData?.dealerHand]
  );

  const [cardShown, setCardShown] = useState({ player: [], dealer: [] });
  const [lastFlippedCardIndex, setLastFlippedCardIndex] = useState({});
  const cardShownRef = useRef(cardShown);
  const lastFlippedCardIndexRef = useRef(lastFlippedCardIndex);
  const previousGameDataRef = useRef(null);

  useEffect(() => {
    cardShownRef.current = cardShown;
  }, [cardShown]);

  useEffect(() => {
    lastFlippedCardIndexRef.current = lastFlippedCardIndex;
  }, [lastFlippedCardIndex]);

  // Arrays for animated card content
  const [animatedPlayerHands, setAnimatedPlayerHands] = useState([]);
  const [animatedDealerHand, setAnimatedDealerHand] = useState([]);
  const animatedDealerCards = useRef(new Set());

  // Card animation hook
  const { flipHandsSequentially } = useCardAnimation(
    gameData,
    cardShown,
    setAnimatedPlayerHands,
    setAnimatedDealerHand,
    setCardShown,
    setLastFlippedCardIndex
  );

  useEffect(() => {
    const initializeConnection = async () => {
      const isProduction =
        typeof window !== "undefined" &&
        window.location.hostname.endsWith("polygonblackjack.com");

      if (!isConnected || chain === 1337 || chain === 31337) {
        // local chain
        setBlackjackAddress("0x5FbDB2315678afecb367f032d93F642f64180aa3");
        let address = playerAddress;
        // If no stored address or not in testAccounts, pick a random
        if (
          !address ||
          !testAccounts.some(
            (acc) => acc.address.toLowerCase() === address.toLowerCase()
          )
        ) {
          const randAccount =
            testAccounts[Math.floor(Math.random() * testAccounts.length)];
          address = randAccount.address;
          setPlayerAddress(address);
          localStorage.setItem("playerAddress", address);
        }

        const account = testAccounts.find(
          (acc) => acc.address.toLowerCase() === address.toLowerCase()
        );

        const url = isProduction
          ? "https://hardhat.polygonblackjack.com/"
          : "http://127.0.0.1:8545/";
        if (account && account.privateKey) {
          const walletSigner = new Wallet(
            account.privateKey,
            new ethers.JsonRpcProvider(url)
          );
          setSigner(walletSigner);
          setProvider(walletSigner.provider);
        } else {
          const rpcProvider = new ethers.JsonRpcProvider(url);
          setProvider(rpcProvider);
          setSigner(null);
        }
      } else if (chain === 80002) {
        // Amoy testnet
        setBlackjackAddress("0x859F72c96cbE1CD1468950F833920845bd5aDeB1");

        // use the browser provider
        if (isConnected && connectedAddress) {
          setPlayerAddress(connectedAddress);
          const connectedProvider = new ethers.BrowserProvider(window.ethereum);
          setProvider(connectedProvider);
          const connectedSigner = await connectedProvider.getSigner();
          setSigner(connectedSigner);
        }
      }
    };

    initializeConnection();
  }, [chain, isConnected, connectedAddress, playerAddress, addAlert]);

  // Once we have a signer & address, fetch data
  useEffect(() => {
    if (playerAddress && provider && blackjackAddress) {
      fetchGameData();
    }
  }, [playerAddress, provider, blackjackAddress, fetchGameData]);

  // Utility to add a face-down placeholder card animation
  const addPlaceholderCardToHand = useCallback((handIndex) => {
    setAnimatedPlayerHands((prev) => {
      const updated = [...prev];
      updated[handIndex] = [...(updated[handIndex] || []), "??"];
      return updated;
    });
    setCardShown((prev) => {
      const updated = { ...prev };
      updated.player = [...(prev.player || [])];
      if (!updated.player[handIndex]) {
        updated.player[handIndex] = [];
      }
      updated.player[handIndex] = [...(prev.player[handIndex] || []), false];
      return updated;
    });
    setLastFlippedCardIndex((prev) => ({
      ...prev,
      [handIndex]: (prev[handIndex] || 0) + 1,
    }));
  }, []);

  // Reset certain UI states
  const resetUIStates = useCallback(() => {
    setShowGameStatus(false);
    setShowPayoutMessage(false);
    setShowLabel(false);
    setShowBetButton(false);
  }, []);

  // Reset ALL UI states
  const resetAllShowStates = useCallback(() => {
    resetUIStates();
    setShowGameActions(false);
  }, [resetUIStates]);

  // Clear the board, reset animations, etc.
  const clearBoard = useCallback(() => {
    setConfettiRecycle(false);
    const initialPlayerHands = [{ cards: [] }];
    const initialDealerHand = [];

    setGameData({
      playerHands: initialPlayerHands,
      dealerHand: initialDealerHand,
      gameStarted: false,
      gameSettled: false,
      currentHandIndex: 0,
      betAmounts: [],
      handStatuses: [],
      payouts: [],
      handReasons: [],
    });

    setAnimatedPlayerHands(initialPlayerHands.map(() => []));
    setAnimatedDealerHand(initialDealerHand);

    // If your DealerHand UI expects exactly 2 placeholders, revert to [false,false].
    // If you truly want them empty, use [] instead.
    const initializedCardShown = {
      player: initialPlayerHands.map((hand) => hand.cards.map(() => false)),
      dealer: [false, false],
    };
    setCardShown(initializedCardShown);
    cardShownRef.current = initializedCardShown;
    setLastFlippedCardIndex({});
    resetAllShowStates();

    previousGameDataRef.current = null;
    setNewColorScheme();
    if (animatedDealerCards.current) {
      animatedDealerCards.current.clear();
    }
  }, [
    setGameData,
    setAnimatedPlayerHands,
    setAnimatedDealerHand,
    setCardShown,
    setNewColorScheme,
    resetAllShowStates,
  ]);

  // Delay showing player's action buttons to let the animation finish
  const showActionsWithDelay = useCallback(async () => {
    const ANIMATION_SETTLE_TIME = 2000;
    await new Promise((resolve) => setTimeout(resolve, ANIMATION_SETTLE_TIME));
    setShowGameActions(true);
  }, []);

  // Check whether a card is already in the face-up or face-down state we want
  const isCardInDesiredState = useCallback(
    (handType, handIndex, cardIndex, faceDown) => {
      const desiredState = !faceDown;

      const cardValue =
        handType === "player"
          ? cardShownRef.current.player?.[handIndex]?.[cardIndex]
          : cardShownRef.current.dealer?.[cardIndex];

      if (faceDown && cardValue !== "??") {
        return false;
      }
      if (!faceDown && cardValue === "??") {
        return false;
      }

      if (handType === "player") {
        return (
          cardShownRef.current.player &&
          cardShownRef.current.player[handIndex] &&
          cardShownRef.current.player[handIndex][cardIndex] === desiredState
        );
      } else if (handType === "dealer") {
        return (
          cardShownRef.current.dealer &&
          cardShownRef.current.dealer[cardIndex] === desiredState
        );
      }
      return false;
    },
    []
  );

  // Animate a single card flipping
  const animateAndRevealCard = useCallback(
    async (handType, handIndex, cardIndex, cardValue, faceDown = false) => {
      if (isCardInDesiredState(handType, handIndex, cardIndex, faceDown)) {
        return;
      }

      // Step 1: show "??"
      if (handType === "player") {
        setAnimatedPlayerHands((prev) => {
          const updated = [...prev];
          if (!updated[handIndex]) updated[handIndex] = [];
          updated[handIndex][cardIndex] = "??";
          return updated;
        });
      } else {
        setAnimatedDealerHand((prev) => {
          const updated = [...prev];
          updated[cardIndex] = "??";
          return updated;
        });
      }
      await new Promise((resolve) => setTimeout(resolve, 350));

      // Step 2: If face-up, reveal actual card
      if (!faceDown) {
        if (handType === "player") {
          setAnimatedPlayerHands((prev) => {
            const updated = [...prev];
            updated[handIndex][cardIndex] = cardValue;
            return updated;
          });
        } else {
          setAnimatedDealerHand((prev) => {
            const updated = [...prev];
            updated[cardIndex] = cardValue;
            return updated;
          });
        }
      }
      await new Promise((resolve) => setTimeout(resolve, 350));

      // Step 3: Mark as face-up in state
      setCardShown((prev) => {
        const updated = { ...prev };
        if (handType === "player") {
          if (!updated.player) updated.player = [];
          if (!updated.player[handIndex]) updated.player[handIndex] = [];
          updated.player[handIndex][cardIndex] = !faceDown;
        } else {
          if (!Array.isArray(updated.dealer)) {
            updated.dealer = [];
          }
          while (updated.dealer.length <= cardIndex) {
            updated.dealer.push(false);
          }
          updated.dealer[cardIndex] = !faceDown;
        }
        return updated;
      });

      await new Promise((resolve) => setTimeout(resolve, 350));
    },
    [isCardInDesiredState]
  );

  // Reveal new player cards as needed
  const handlePlayerHandUpdates = useCallback(async () => {
    const currentPlayerHands = gameData.playerHands || [];
    const previousPlayerHands = previousGameDataRef.current?.playerHands || [];

    for (
      let handIndex = 0;
      handIndex < currentPlayerHands.length;
      handIndex++
    ) {
      const currentHand = currentPlayerHands[handIndex]?.cards || [];
      const previousHand = previousPlayerHands[handIndex]?.cards || [];
      for (let cardIndex = 0; cardIndex < currentHand.length; cardIndex++) {
        const currentCard = currentHand[cardIndex];
        const previousCard = previousHand[cardIndex];
        if (currentCard && currentCard !== previousCard) {
          if (!isCardInDesiredState("player", handIndex, cardIndex, false)) {
            await animateAndRevealCard(
              "player",
              handIndex,
              cardIndex,
              currentCard
            );
          }
        }
      }
    }
  }, [gameData.playerHands, animateAndRevealCard, isCardInDesiredState]);

  // Check if there's a new hand => a split
  const detectSplit = useCallback(() => {
    const prevCount = previousGameDataRef.current?.playerHands?.length || 1;
    const currCount = gameData.playerHands?.length || 1;
    return currCount > prevCount;
  }, [gameData.playerHands]);

  // Animate new split hands
  const handleSplit = useCallback(async () => {
    const previousHandCount =
      previousGameDataRef.current?.playerHands?.length || 1;
    const newHands = gameData.playerHands.slice(previousHandCount);
    for (let i = 0; i < newHands.length; i++) {
      const handIndex = previousHandCount + i;
      const newHand = newHands[i];
      const firstCard = newHand.cards?.[0];
      const secondCard = newHand.cards?.[1];

      if (!firstCard || !secondCard) {
        addAlert({
          status: "error",
          title: "Split Failed",
          description: "Cannot split, insufficient cards in the new hand.",
        });
        continue;
      }

      // Put the first card face-up, second as "??"
      setAnimatedPlayerHands((prev) => {
        const updated = [...prev];
        updated[handIndex] = [firstCard, "??"];
        return updated;
      });
      setCardShown((prev) => ({
        ...prev,
        player: [...(prev.player || []), [true, false]],
      }));

      await animateAndRevealCard("player", handIndex, 1, secondCard, false);
    }
  }, [gameData.playerHands, animateAndRevealCard, addAlert]);

  // Main effect that reacts to changes in gameData
  const handleGameUpdate = useCallback(async () => {
    if (!gameData) return;

    updateQueue.current = updateQueue.current.then(async () => {
      try {
        const previousGameData = previousGameDataRef.current;

        // If the game just settled
        if (gameData.gameSettled) {
          await handlePlayerHandUpdates();

          // Reveal dealer's full hand
          const dealerHand = gameData.dealerHand || [];
          for (let i = 0; i < dealerHand.length; i++) {
            const cardValue = dealerHand[i];
            await animateAndRevealCard("dealer", null, i, cardValue, false);
            animatedDealerCards.current.add(`${i}-${cardValue}`);
          }
          flipHandsSequentially("dealer");

          setShowGameStatus(true);
          setShowPayoutMessage(true);

          // If betMax is on, clamp bet if it exceeds new max
          if (betMaxEnabled) {
            const freshMaxBetStr = gameData.maxBet?.toString() || "0";
            const freshMaxBet = parseFloat(freshMaxBetStr);
            const currentBetNum = parseFloat(betAmount);
            if (currentBetNum > freshMaxBet) {
              setBetAmount(freshMaxBetStr);
            }
          }

          setShowGameActions(false);
          setShowBetButton(true);

          // If the game has just begun
        } else if (
          gameData.gameStarted &&
          (!previousGameData || !previousGameData.gameStarted)
        ) {
          setAnimatedPlayerHands([]);
          setAnimatedDealerHand([]);
          setCardShown(initializeCardShown());
          animatedDealerCards.current.clear();

          // Deal initial cards
          const initialDealOrder = [
            { handType: "player", handIndex: 0, cardIndex: 0, faceDown: false },
            {
              handType: "dealer",
              handIndex: null,
              cardIndex: 0,
              faceDown: false,
            },
            {
              handType: "player",
              handIndex: 0,
              cardIndex: 1,
              faceDown: false,
            },
            {
              handType: "dealer",
              handIndex: null,
              cardIndex: 1,
              faceDown: true,
            },
          ];

          for (const deal of initialDealOrder) {
            const { handType, handIndex, cardIndex, faceDown } = deal;
            const cardValue =
              handType === "player"
                ? gameData.playerHands?.[handIndex]?.cards?.[cardIndex]
                : gameData.dealerHand?.[cardIndex];

            if (cardValue) {
              await animateAndRevealCard(
                handType,
                handIndex,
                cardIndex,
                cardValue,
                faceDown
              );
            }
          }

          // Handle any extra cards
          const playerHands = gameData.playerHands || [];
          for (let handIndex = 0; handIndex < playerHands.length; handIndex++) {
            const playerHand = playerHands[handIndex] || {};
            const startCardIndex = handIndex === 0 ? 2 : 0;
            for (
              let cardIndex = startCardIndex;
              cardIndex < (playerHand.cards?.length || 0);
              cardIndex++
            ) {
              const cardValue = playerHand.cards[cardIndex];
              if (cardValue) {
                await animateAndRevealCard(
                  "player",
                  handIndex,
                  cardIndex,
                  cardValue,
                  false
                );
              }
            }
          }

          await showActionsWithDelay();
          setShowBetButton(false);

          // If a split happened
        } else if (detectSplit()) {
          await handleSplit();
          await showActionsWithDelay();

          // Else ongoing updates
        } else if (previousGameData) {
          await handlePlayerHandUpdates();

          // Animate new dealer cards
          const currentDealerHand = gameData.dealerHand || [];
          const previousDealerHand = previousGameData.dealerHand || [];
          for (let i = 0; i < currentDealerHand.length; i++) {
            const currentCard = currentDealerHand[i];
            const previousCard = previousDealerHand[i];
            if (
              currentCard &&
              currentCard !== previousCard &&
              !animatedDealerCards.current.has(`${i}-${currentCard}`)
            ) {
              await animateAndRevealCard("dealer", null, i, currentCard, false);
              animatedDealerCards.current.add(`${i}-${currentCard}`);
            }
          }

          if (gameData.gameStarted && !gameData.gameSettled) {
            await showActionsWithDelay();
            setShowBetButton(false);
          }
        }

        // Update reference
        previousGameDataRef.current = JSON.parse(JSON.stringify(gameData));
      } catch (error) {
        addAlert({
          status: "error",
          title: "Game Update Error",
          description: "An error occurred while updating the game state.",
        });
      }
    });
  }, [
    gameData,
    handlePlayerHandUpdates,
    animateAndRevealCard,
    flipHandsSequentially,
    handleSplit,
    detectSplit,
    addAlert,
    initializeCardShown,
    showActionsWithDelay,
    animatedDealerCards,
    betMaxEnabled,
    betAmount,
  ]);

  // Run handleGameUpdate whenever gameData changes
  useEffect(() => {
    handleGameUpdate();
  }, [handleGameUpdate]);

  // Hide shuffling screen once the game is started
  useEffect(() => {
    if (gameData?.gameStarted) {
      setShowShuffling(false);
    }
  }, [gameData?.gameStarted]);

  // If there's a blackjack, show confetti
  useEffect(() => {
    if (gameData?.handStatuses?.includes("BLACKJACK!") && !confettiRecycle) {
      setConfettiRunning(true);
      setConfettiRecycle(true);
    }
  }, [gameData?.handStatuses, confettiRecycle]);

  // Called when the user modifies bet from the UI
  const handleBetAmountChange = useCallback((value) => {
    setBetAmount(value);
  }, []);

  // Start a new game
  const startNewGame = useCallback(async () => {
    if (!signer) {
      addAlert({
        status: "error",
        title: "Signer not available",
        description: "Ensure you are connected correctly.",
      });
      return;
    }

    if (!playerAddress) {
      addAlert({
        status: "error",
        title: "Player address not available",
        description: "Ensure you are connected correctly.",
      });
      return;
    }

    // Show shuffling right away
    setShowShuffling(true);

    let attempts = 0;
    const maxAttempts = 10;
    let success = false;
    let lastError = null;

    while (attempts < maxAttempts && !success) {
      attempts += 1;

      try {
        // If retrying and BetMax is on, fetch new maxBet from fresh gameData
        let numericBetAmount = parseFloat(betAmount);
        if (attempts > 1 && betMaxEnabled) {
          const updatedData = await fetchGameData();
          if (updatedData) {
            const latestMaxBet = updatedData.maxBet || "0";
            // Possibly clamp if needed
            if (numericBetAmount > parseFloat(latestMaxBet)) {
              setBetAmount(latestMaxBet);
              numericBetAmount = parseFloat(latestMaxBet);
            }
          }
        }

        if (isNaN(numericBetAmount) || numericBetAmount <= 0) {
          throw new Error("Invalid or zero bet amount.");
        }

        localStorage.setItem("betAmount", numericBetAmount.toString());
        setIsLoading(true);
        setLoadingAction("Placing Bet");

        await executeContractFunction({
          signer,
          blackjackAddress,
          setIsLoading,
          fetchGameData,
          addAlert,
          functionName: "startGame",
          functionArgs: [],
          value: ethers.parseEther(numericBetAmount.toString()),
          setLoadingAction,
          maxRetries: 1,
          lastRetry: attempts === maxAttempts,
        });

        // If transaction succeeded, mark success
        success = true;
      } catch (error) {
        lastError = error;
      } finally {
        setLoadingAction("");
        setIsLoading(false);
      }
    }

    // Now that the transaction is successful, truly clear the old board
    if (success) {
      clearBoard();
      // Immediately fetch updated game data
      await fetchGameData();
    }

    // Hide shuffling if done
    setShowShuffling(false);

    // If it never succeeded, show the error
    if (!success && lastError) {
      addAlert({
        status: "error",
        title: "Transaction Failed",
        description:
          lastError.message || "An error occurred while starting a new game.",
      });
    }
  }, [
    signer,
    playerAddress,
    betAmount,
    betMaxEnabled,
    blackjackAddress,
    fetchGameData,
    addAlert,
    setShowShuffling,
    setIsLoading,
    setLoadingAction,
    clearBoard,
  ]);

  // Handle actions: hit, stand, doubleDown, split
  const handleAction = useCallback(
    (actionFunction) => async (handIndex) => {
      if (!signer || !playerAddress) {
        addAlert({
          status: "error",
          title: "Signer Not Available",
          description: "Signer is not available, try reconnecting.",
        });
        return;
      }

      resetUIStates();
      const actionMap = {
        hit: "Hitting",
        stand: "Standing",
        doubleDown: "Doubling Down",
        split: "Splitting",
      };
      setLoadingAction(actionMap[actionFunction] || "Loading");

      if (actionFunction === "hit" || actionFunction === "doubleDown") {
        addPlaceholderCardToHand(handIndex);
      }

      if (actionFunction === "split") {
        const currentHand = gameData.playerHands?.[handIndex] || {};
        const firstCard = currentHand.cards?.[0];
        const secondCard = currentHand.cards?.[1];
        const isSplitAce = currentHand.isSplitAce;

        if (!firstCard || !secondCard) {
          addAlert({
            status: "error",
            title: "Split Failed",
            description: "Cannot split, insufficient cards in hand.",
          });
          setLoadingAction("");
          return;
        }

        // Quickly update UI to show 2 hands
        setGameData((prev) => {
          const updated = { ...prev };
          updated.playerHands = [...(prev.playerHands || [])];
          updated.playerHands[handIndex] = {
            ...updated.playerHands[handIndex],
            cards: [firstCard, "??"],
            isSplitAce,
          };
          updated.playerHands.splice(handIndex + 1, 0, {
            ...updated.playerHands[handIndex],
            cards: [secondCard, "??"],
            isSplitAce,
          });
          return updated;
        });

        setAnimatedPlayerHands((prev) => {
          const updated = [...prev];
          updated[handIndex] = [firstCard, "??"];
          updated.splice(handIndex + 1, 0, [secondCard, "??"]);
          return updated;
        });

        setCardShown((prev) => {
          const updatedPlayer = [...(prev.player || [])];
          if (updatedPlayer[handIndex]) {
            updatedPlayer[handIndex][1] = false;
          }
          updatedPlayer.push([true, false]);
          return {
            ...prev,
            player: updatedPlayer,
          };
        });

        setLastFlippedCardIndex((prev) => ({
          ...prev,
          [handIndex]: 0,
          [handIndex + 1]: 0,
        }));
      }

      // If doubling or splitting, add extra bet
      let value = null;
      if (actionFunction === "doubleDown" || actionFunction === "split") {
        const currentBet =
          gameData.betAmounts?.[gameData.currentHandIndex] || "0";
        value = ethers.parseEther(parseFloat(currentBet).toString());
      }

      try {
        await executeContractFunction({
          signer,
          blackjackAddress,
          setIsLoading,
          fetchGameData,
          addAlert,
          functionName: actionFunction,
          functionArgs: [],
          value,
          setLoadingAction,
          maxRetries: 5,
        });
      } catch (error) {
        addAlert({
          status: "error",
          title: "Transaction Failed",
          description:
            error.message ||
            `An error occurred while performing ${actionFunction}.`,
        });
      }

      await fetchGameData();

      // If hitting/doubling/splitting, ensure we re-check face-down states
      if (["hit", "doubleDown", "split"].includes(actionFunction)) {
        setCardShown((prev) => ({
          ...prev,
          player: (gameData.playerHands || []).map((hand, hIdx) =>
            (hand.cards || []).map((_, cIdx) =>
              prev.player[hIdx] && prev.player[hIdx][cIdx] !== undefined
                ? prev.player[hIdx][cIdx]
                : false
            )
          ),
        }));
        flipHandsSequentially("player");
      }

      setLoadingAction("");
    },
    [
      signer,
      playerAddress,
      addAlert,
      resetUIStates,
      addPlaceholderCardToHand,
      fetchGameData,
      flipHandsSequentially,
      gameData.currentHandIndex,
      gameData.playerHands,
      gameData.betAmounts,
      blackjackAddress,
      setGameData,
      setIsLoading,
    ]
  );

  // For convenience
  const currentHand = gameData?.playerHands?.[gameData.currentHandIndex] || {};
  const getCardValueLocal = (card) =>
    card ? (card.length === 3 ? card.slice(0, 2) : card.slice(0, 1)) : "0";

  // Conditions for doubling and splitting
  const canDoubleDown =
    currentHand.cards?.length === 2 &&
    !gameData.gameSettled &&
    !currentHand.isSplitAce;
  const canSplit =
    currentHand.cards?.length === 2 &&
    getCardValueLocal(currentHand.cards[0]) ===
      getCardValueLocal(currentHand.cards[1]) &&
    !gameData.gameSettled &&
    !currentHand.isSplitAce;

  // Handle account switching in local dev
  const handleAccountChange = useCallback(
    (newAddress) => {
      clearBoard();
      setPlayerAddress(newAddress);
      localStorage.setItem("playerAddress", newAddress);
    },
    [clearBoard]
  );

  return (
    <Box w="100%" h="100%" px={fullscreen ? 0 : 3}>
      <Box
        w="100%"
        minH={fullscreen ? "100dvh" : "auto"}
        bg="radial-gradient(circle, #35654d 0%, #2f5a46 40%, #254c3a 100%)"
        borderColor="#0E3E26"
        borderRadius={fullscreen ? "0" : "13px"}
        borderWidth={fullscreen ? 0 : 4}
        py={fullscreen ? 5 : 0}
        position="relative"
        align="center"
        justify="center"
        boxShadow="inset 0 0 15px rgba(0, 0, 0, 0.3), inset 0 0 3px rgba(109, 134, 112, 0.36)"
        alignContent="center"
        overflow="visible"
        mb={fullscreen ? 0 : 3}
      >
        {/* Fullscreen Button */}
        <IconButton
          icon={fullscreen ? <FaCompress /> : <FaExpand />}
          onClick={toggleFullscreen}
          aria-label="Toggle Fullscreen"
          colorScheme="whiteAlpha"
          size="lg"
          position="absolute"
          right={0}
          top={isStandalone && fullscreen ? 8 : 0}
          _hover={{ transform: "scale(1.05)" }}
          cursor="pointer"
          zIndex={1500}
          variant="ghost"
        />

        {/* Confetti */}
        {confettiRunning && (
          <Confetti
            run={confettiRunning}
            recycle={confettiRecycle}
            tweenDuration={10000}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 1000,
            }}
          />
        )}

        <Box overflowX="auto" w="100%">
          <VStack w="100%">
            {showShuffling ? (
              <Box h="540px" w="100%">
                <Shuffling colorScheme={colorScheme} />
              </Box>
            ) : !gameData?.gameStarted && !isLoading ? (
              // Show welcome screen if no active game
              <Box h="540px">
                <WelcomeScreen
                  ethAmount={dealerBankroll}
                  escrowAmount={totalEscrow}
                />
              </Box>
            ) : (
              // Main table area
              <Box h="540px">
                {/* Dealer cards */}
                <Box h="208px">
                  <DealerHand
                    animatedDealerHand={animatedDealerHand}
                    colorScheme={colorScheme}
                    gameStarted={gameData.gameStarted}
                    ethAmount={dealerBankroll}
                    escrowAmount={totalEscrow}
                    gameLoaded={gameData.gameLoaded}
                    handIndex={0}
                  />
                </Box>

                {/* Player cards */}
                <Box>
                  <PlayerHands
                    animatedPlayerHands={animatedPlayerHands}
                    colorScheme={colorScheme}
                    gameData={gameData}
                    showLabel={showLabel}
                    showGameActions={showGameActions}
                    isLoading={isLoading}
                    handleAction={handleAction}
                    canDoubleDown={canDoubleDown}
                    canSplit={canSplit}
                    loadingAction={loadingAction}
                    showGameStatus={showGameStatus}
                    showPayoutMessage={showPayoutMessage}
                  />
                </Box>
              </Box>
            )}

            {/* Betting Controls */}
            <Box h="70px">
              {(!gameData?.gameStarted || showBetButton) && (
                <Betting
                  isLoading={isLoading}
                  betAmount={betAmount}
                  handleBetAmountChange={handleBetAmountChange}
                  startNewGame={startNewGame}
                  loadingAction={loadingAction}
                  dealerBankroll={dealerBankroll}
                  maxBet={gameData?.maxBet}
                  playerBalance={playerBankroll}
                  betMaxEnabled={betMaxEnabled}
                  setBetMaxEnabled={setBetMaxEnabled}
                  fetchGameData={fetchGameData}
                />
              )}
            </Box>

            {/* Player account controls */}
            <Box h="65px">
              <PlayerAccount
                currentAccount={playerAddress}
                onAccountChange={handleAccountChange}
                playerBalance={playerBankroll}
                isDisabled={
                  isLoading || (gameData?.gameStarted && !showBetButton)
                }
              />
            </Box>
          </VStack>
        </Box>
      </Box>
    </Box>
  );
};

export default Blackjack;
