// components/Hand.js
import React from "react";
import { Box, Text, HStack } from "@chakra-ui/react";
import { motion, AnimatePresence, LayoutGroup } from "framer-motion";
import Card from "./Card";
import PropTypes from "prop-types";

const MotionBox = motion.create(Box);

const Hand = ({
  cards = [],
  handStatus,
  handIndex,
  label,
  betAmount,
  ethAmount,
  payout,
  colorScheme,
  showGameStatus,
  showPayoutMessage,
  isSplit,
  reason,
}) => {
  const OVERLAP_STEPS = {
    6: 80,
    5: 60,
    4: 30,
    3: 0,
    2: 0,
    1: 0,
  };

  const getOverlapStep = (numCards) => {
    if (isSplit) return 66;
    if (numCards >= 6) return OVERLAP_STEPS[6];
    if (numCards >= 5) return OVERLAP_STEPS[5];
    if (numCards >= 4) return OVERLAP_STEPS[4];
    if (numCards >= 3) return OVERLAP_STEPS[3];
    return OVERLAP_STEPS[numCards] || 0;
  };

  const overlapStep = getOverlapStep(cards.length);

  const renderCards = () => {
    return cards.map((card, index) => {
      if (!card) {
        card = "??"; // Placeholder for back of the card
      }
      const rank = card.slice(0, -1);
      const suit = card.slice(-1);
      const cardKey = `${handIndex}-${index}`;
      const marginLeft = index === 0 ? "0px" : `-${overlapStep}px`;
      //console.log(`CARD RENDER: ${cardKey} ${rank} ${suit}`);

      return (
        <MotionBox
          key={cardKey}
          layout
          initial={{ scale: 0.75, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.75, opacity: 0 }}
          transition={{ duration: 0.3 }}
          ml={marginLeft}
          position="relative"
          zIndex={index + 1}
          h="100%"
        >
          <Card
            rank={rank}
            suit={suit}
            colorScheme={colorScheme}
            isSplit={isSplit}
          />
        </MotionBox>
      );
    });
  };

  const bet = parseFloat(betAmount);
  const payoutAmount = parseFloat(payout);
  const formattedPayout = parseFloat(payoutAmount.toFixed(3));

  let payoutMessage = "";
  let payoutTextProps = {};

  if (!isNaN(bet) && !isNaN(payoutAmount)) {
    if (payoutAmount > bet) {
      payoutMessage = isSplit
        ? ["YOU WON", `${formattedPayout}!`]
        : `YOU WON ${formattedPayout}!`;
      payoutTextProps = {
        fontSize: isSplit ? "lg" : "2xl",
        bgGradient: "linear(to-l, #FFEA00, #FFFF00)",
        bgClip: "text",
        fontWeight: "extrabold",
        textShadow: "6px 6px 20px rgba(0, 0, 0, 0.5)",
        as: "b",
      };
    } else if (payoutAmount === bet) {
      payoutMessage = `${formattedPayout} returned`;
      payoutTextProps = {
        fontSize: isSplit ? "lg" : "2xl",
        color: "whitesmoke",
        as: "b",
      };
    }
  }

  let dealerAmount = null;

  if (!isNaN(parseFloat(ethAmount))) {
    dealerAmount = parseFloat(ethAmount).toFixed(3);
  }

  return (
    <Box h="100%" p={2}>
      {label === "dealer" && dealerAmount !== null && (
        <Text fontSize="lg" color="whitesmoke" p={1} display="inline-flex">
          Dealer {dealerAmount}
        </Text>
      )}

      {label !== "dealer" && (
        <Box h="70px" align="center" p={2}>
          {handStatus && showGameStatus && (
            <>
              <Text fontSize={isSplit ? "lg" : "2xl"} color="whitesmoke" as="b">
                {handStatus}
              </Text>
              <Text fontSize={isSplit ? "sm" : "md"} color="whitesmoke">
                {reason}
              </Text>
            </>
          )}
        </Box>
      )}
      <LayoutGroup>
        <Box position="relative" display="flex" justifyContent="center">
          <HStack m={3} spacing={0} justify="center" alignItems="center">
            <AnimatePresence initial={false}>{renderCards()}</AnimatePresence>
          </HStack>
        </Box>
      </LayoutGroup>
      {label !== "dealer" && (
        <Box>
          <Text
            fontSize={isSplit ? "md" : "lg"}
            color="whitesmoke"
            alignItems="center"
            style={{ verticalAlign: "bottom" }}
          >
            {!isNaN(bet) ? (
              <Text display="inline-flex">
                Bet {parseFloat(bet).toFixed(3)}
              </Text>
            ) : (
              "\u00a0"
            )}
          </Text>
          {payoutMessage && showPayoutMessage && (
            <Text
              textShadow="0px 5px 15px rgba(0,0,0,0.3)"
              whiteSpace="nowrap"
              {...payoutTextProps}
            >
              {Array.isArray(payoutMessage) ? (
                <>
                  {payoutMessage[0]} <br /> {payoutMessage[1]}
                </>
              ) : (
                payoutMessage
              )}
            </Text>
          )}
        </Box>
      )}
    </Box>
  );
};

Hand.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.string).isRequired,
  handStatus: PropTypes.string,
  handIndex: PropTypes.number.isRequired,
  label: PropTypes.string,
  betAmount: PropTypes.string,
  ethAmount: PropTypes.string,
  payout: PropTypes.string,
  colorScheme: PropTypes.string.isRequired,
  showGameStatus: PropTypes.bool,
  showPayoutMessage: PropTypes.bool,
  isSplit: PropTypes.bool,
  reason: PropTypes.string,
  isSplitAce: PropTypes.bool,
};

export default Hand;
