import React from "react";
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Box,
} from "@chakra-ui/react";
import { ConnectKitButton } from "connectkit";
import { useAccount } from "wagmi";
import { testAccounts } from "../testAccounts";

const PlayerAccount = ({
  currentAccount,
  onAccountChange,
  playerBalance,
  isDisabled,
}) => {
  const { isConnected } = useAccount();
  const handleAccountChange = (selectedAddress) => {
    onAccountChange(selectedAddress);
  };

  return (
    <HStack pt="3" spacing={3} justifyContent="center" alignItems="center">
      {!isConnected && (
        <Menu>
          <MenuButton
            as={Button}
            backgroundColor="#35654d"
            color="#F9F6EE"
            fontSize="16px"
            borderRadius="10px"
            border="1px solid #F9F6EE"
            boxShadow="0px 4px 8px rgba(0, 0, 0, 0.25)"
            _hover={{
              backgroundColor: "#2f5a46",
              cursor: "pointer",
            }}
            disabled={isDisabled}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span>
                {testAccounts.find(
                  (account) => account.address === currentAccount
                )?.label || "Select Account"}
                : {parseFloat(playerBalance || 0).toFixed(3)}
              </span>
            </div>
          </MenuButton>

          <MenuList
            bg="#35654d"
            border="1px solid #F9F6EE"
            zIndex="10"
            maxH="300px"
            overflowY="auto"
          >
            {testAccounts.length > 0 ? (
              testAccounts.map((account) => (
                <MenuItem
                  key={account.address}
                  onClick={() => handleAccountChange(account.address)}
                  _hover={{ bg: "#2f5a46", color: "#F9F6EE" }}
                  bg="#35654d"
                  color="#F9F6EE"
                >
                  {account.label}
                </MenuItem>
              ))
            ) : (
              <MenuItem isDisabled>No accounts found</MenuItem>
            )}
          </MenuList>
        </Menu>
      )}

      <ConnectKitButton.Custom>
        {({ isConnected, show }) => {
          if (isConnected) {
            return (
              <Box as="span">
                <ConnectKitButton
                  showAvatar={true}
                  showBalance={true}
                  showNetwork={true}
                  onClick={show}
                />
              </Box>
            );
          } else {
            return (
              <Button
                onClick={show}
                backgroundColor="#35654d"
                color="#F9F6EE"
                fontSize="16px"
                borderRadius="10px"
                border="1px solid #F9F6EE"
                boxShadow="0px 4px 8px rgba(0, 0, 0, 0.25)"
                isDisabled={isDisabled}
                _hover={{
                  backgroundColor: "#2f5a46",
                  cursor: "pointer",
                }}
              >
                Connect Wallet
              </Button>
            );
          }
        }}
      </ConnectKitButton.Custom>
    </HStack>
  );
};

export default PlayerAccount;
