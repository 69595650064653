import { useState, useCallback, useEffect, useRef } from "react";
import { ethers } from "ethers";
import BlackjackABI from "../BlackjackABI.json";

const useFetchGameData = (address, provider, blackjackAddress) => {
  const [gameData, setGameData] = useState({
    playerHands: [],
    dealerHand: [],
    betAmounts: [],
    handStatuses: [],
    handReasons: [],
    payouts: [],
    gameStarted: false,
    gameSettled: false,
    currentHandIndex: 0,
    maxBet: "0",
    totalEscrow: "0",
    dealerBankroll: "0.000",
    playerBankroll: "0.000",
  });

  const [isLoading, setIsLoading] = useState(false);
  const isUpdatingRef = useRef(false);

  const formatCard = (card) => {
    const rankMapping = [
      "A",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "J",
      "Q",
      "K",
    ];
    const suitMapping = ["♠", "♥", "♦", "♣"];
    const rankNumber = Number(card.rank);
    const suitNumber = Number(card.suit);
    if (rankNumber === 0) return "??"; // No card
    const rank = rankMapping[rankNumber - 1] || "??";
    const suit = suitMapping[suitNumber] || "??";
    return `${rank}${suit}`;
  };

  const fetchGameData = useCallback(async () => {
    if (isUpdatingRef.current || !address || !provider || !blackjackAddress) {
      return null;
    }
    isUpdatingRef.current = true;
    setIsLoading(true);

    try {
      const contract = new ethers.Contract(
        blackjackAddress,
        BlackjackABI,
        provider
      );

      // Get the overall game status for this address
      const { game, totalEscrowValue } = await contract.gameStatus(address);

      // Parse out player's hands
      const playerHands = game.playerHands?.length
        ? game.playerHands.map((h) => ({
            cards: h.cards.map(formatCard),
            isSplitAce: h.isSplitAce,
          }))
        : [{ cards: [], isSplitAce: false }];

      // Parse out dealer's hand
      const dealerHand = game.dealerHand.cards?.length
        ? game.dealerHand.cards.map(formatCard)
        : [];

      // Convert bet + payout from BigNumber to string Ether
      const betAmounts =
        game.playerHands?.map((hand) => ethers.formatEther(hand.bet)) || [];
      const payouts =
        game.playerHands?.map((hand) => ethers.formatEther(hand.payout)) || [];

      // Map results
      const resultMapping = [
        "NotSet",
        "BLACKJACK!",
        "Push",
        "Player Won",
        "Dealer Won",
        "Busted",
      ];
      const handStatuses =
        game.playerHands?.map(
          (hand) => resultMapping[hand.result] || "Unknown"
        ) || [];
      const handReasons =
        game.playerHands?.map((hand) => hand.reason || "") || [];

      // totalEscrowValue is a BigNumber. Convert to Ether string, then float
      const totalEscrow = ethers.formatEther(totalEscrowValue);

      // Grab balances: dealer (contract) and player
      const [contractBalanceWei, playerBalanceWei] = await Promise.all([
        provider.getBalance(blackjackAddress),
        provider.getBalance(address),
      ]);
      const contractBalanceEther = ethers.formatEther(contractBalanceWei);
      const playerBalanceEther = ethers.formatEther(playerBalanceWei);

      // Convert to floats
      const contractBalanceNumber = parseFloat(contractBalanceEther);
      const totalEscrowNumber = parseFloat(totalEscrow);
      const playerBalanceNumber = parseFloat(playerBalanceEther);

      // (1) We consider the dealer's "available" bankroll as (contractBalance - totalEscrow).
      // (2) Our maxBet is 1/10th of that amount.
      const dealerAvailable = contractBalanceNumber - totalEscrowNumber;
      const rawMaxBet = dealerAvailable / 10;

      // Clamp the rawMaxBet so we never exceed player's actual on-chain balance
      const clampValue = Math.min(rawMaxBet, playerBalanceNumber);
      const finalMaxBet = clampValue > 0 ? clampValue.toFixed(3) : "0";

      // Build new state
      const newGameData = {
        playerHands,
        dealerHand,
        betAmounts,
        payouts,
        handStatuses,
        handReasons,
        gameStarted: playerHands.some((hand) =>
          hand.cards.some((c) => c !== "??")
        ),
        gameSettled: game.settled,
        currentHandIndex: Number(game.currentHandIndex),

        // Our new computed maxBet
        maxBet: finalMaxBet,

        // Format to show 3 decimals in the UI
        totalEscrow: totalEscrowNumber.toFixed(3),
        dealerBankroll: contractBalanceNumber.toFixed(3),
        playerBankroll: playerBalanceNumber.toFixed(3),
      };

      setGameData(newGameData);
      return newGameData;
    } catch (err) {
      // do not update game state on error
      console.error("Error fetching game data:", err);
    } finally {
      isUpdatingRef.current = false;
      setIsLoading(false);
    }
  }, [address, provider, blackjackAddress]);

  // Auto-fetch on mount or if dependencies change
  useEffect(() => {
    fetchGameData();
  }, [fetchGameData]);

  return {
    gameData,
    setGameData,
    fetchGameData,
    isLoading,
    setIsLoading,
  };
};

export default useFetchGameData;
